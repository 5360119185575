import Splide from '@splidejs/splide';

const advantagesSections = document.querySelectorAll('.js-testimonials');

if (advantagesSections.length > 0) {
    const handleSlider = (sliderEl, sliderInstance) => {
        if (!sliderInstance) {
            sliderInstance = new Splide(sliderEl, {
                pagination: false,
                arrows: false,
                type: 'loop',
                perPage: 1,
                autoplay: true,
                interval: 8000,
                flickMaxPages: 1,
                updateOnMove: true,
                pauseOnHover: false,
                pauseOnFocus: false,
            }).mount();
        }

        return sliderInstance;
    };

    advantagesSections.forEach(section => {
        const sliderEl = section.querySelector('.testimonials__carousel');
        if (!sliderEl) return;
        let sliderInstance = null;
        window.addEventListener('resize', () => {
            sliderInstance = handleSlider(sliderEl, sliderInstance);
            console.log(sliderInstance);
        });
        sliderInstance = handleSlider(sliderEl, sliderInstance);
    });
}
